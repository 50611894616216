// App.js
import React, { useState, useEffect } from "react";
import { ConfigProvider, theme } from "antd";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { Layout, message } from "antd";
import socket from "./socket";

import AdminPage from "./pages/AdminPage";
import CloserPage from "./pages/CloserPage";
import EmployeePage from "./pages/EmployeePage";
import Login from "./components/Shared/Login";
import ProtectedRoute from "./components/Shared/ProtectedRoute";
import AppHeader from "./components/Shared/Header";

import LeadList from "./components/Shared/LeadList";
import LeadStatistics from "./components/Admin/LeadStatistics";
import TeamStatistics from "./components/Admin/TeamStatistics";
import WhitelistManagement from "./components/Admin/WhitelistManagement";
import TeamManagement from "./components/Admin/TeamManagement";
import LeadImport from "./components/Admin/LeadImport";
import LeadPreprocess from "./components/Admin/LeadPreprocess";

import DatabaseManager from './components/Admin/DatabaseManager';
import Online from './components/OnlineUsersList/OnlineUsersList'

import UserProfile from "./components/Shared/UserProfile";
import authService from "./services/authService";

const { Content, Footer } = Layout;

const App = () => {
	const [user, setUser] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [darkMode, setDarkMode] = useState(false); // Состояние для темы
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const token = localStorage.getItem("token");

		if (token) {
			// При наличии токена, отправляем событие authenticate
			socket.emit("authenticate", { token });

			// Обработка события обновления списка онлайн-пользователей
			socket.on("updateOnlineUsers", (onlineUserIds) => {
				console.log("Онлайн пользователи:", onlineUserIds);
				// Здесь вы можете обновить состояние вашего приложения
				// для отображения списка онлайн-пользователей
			});
		}

		// Очистка при размонтировании компонента
		return () => {
			socket.off("updateOnlineUsers");
		};
	}, []);

	useEffect(() => {
		const savedTheme = localStorage.getItem("theme") || "light";
		setDarkMode(savedTheme === "dark");
		document.body.classList.toggle(
			"ant-design-dark-theme",
			savedTheme === "dark"
		);
	}, []);

	const toggleTheme = () => {
		const newTheme = darkMode ? "light" : "dark";
		setDarkMode(!darkMode);
		localStorage.setItem("theme", newTheme); // Сохраняем тему в localStorage
		document.body.classList.toggle(
			"ant-design-dark-theme",
			newTheme === "dark"
		);
	};

	// Проверка и загрузка текущего пользователя
	const fetchUser = async () => {
		try {
			const response = await authService.getCurrentUserFromToken();
			setUser(response); // Устанавливаем данные пользователя
		} catch (error) {
			message.error("Ошибка авторизации, выполните повторный вход.");
			authService.logout();
			navigate("/login");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			fetchUser();
		} else {
			setIsLoading(false);
		}
	}, []);

	const onLogout = () => {
		authService.logout();
		setUser(null);
		navigate("/login");
	};

	if (isLoading) {
		return <div>Loading...</div>;
	}

	return (
		<ConfigProvider
			theme={{
				algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
			}}
		>
			<Layout className="layout">

				{location.pathname !== "/login" && (
					<AppHeader
						user={user}
						onLogout={onLogout}
						toggleTheme={toggleTheme}
						darkMode={darkMode}
					/>
				)}
				<Content style={{ padding: "50px 50px" }}>

					<Routes>
						<Route
							path="/login"
							element={<Login onLogin={(userData) => setUser(userData)} />}
						/>
						<Route
							path="/admin"
							element={
								<ProtectedRoute isAllowed={user?.role === "admin"}>
									<AdminPage user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/lead-list"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<LeadList user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/lead-stat"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<LeadStatistics user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/team-stat"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<TeamStatistics user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin/ip-whitelist"
							element={
								<ProtectedRoute isAllowed={user?.role === "admin"}>
									<WhitelistManagement user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/team-management"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<TeamManagement user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/base"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<DatabaseManager user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/online"
							element={
								<ProtectedRoute
									isAllowed={user?.role === "admin" || user?.role === "closer"}
								>
									<Online user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin/lead-import"
							element={
								<ProtectedRoute isAllowed={user?.role === "admin"}>
									<LeadImport user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/admin/lead-preprocess"
							element={
								<ProtectedRoute isAllowed={user?.role === "admin"}>
									<LeadPreprocess user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/closer"
							element={
								<ProtectedRoute isAllowed={user?.role === "closer"}>
									<CloserPage user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/employee"
							element={
								<ProtectedRoute isAllowed={user?.role === "employee"}>
									<EmployeePage user={user} />
								</ProtectedRoute>
							}
						/>
						<Route
							path="/profile"
							element={
								<ProtectedRoute
									isAllowed={["admin", "closer", "employee"].includes(
										user?.role
									)}
								>
									<UserProfile user={user} />
								</ProtectedRoute>
							}
						/>
						<Route path="/" element={<Navigate to="/login" />} />
					</Routes>
				</Content>
				<Footer style={{ textAlign: "center" }}>MateCRM ©2024</Footer>
			</Layout>
		</ConfigProvider>
	);
};

const AppWrapper = () => {
	return (
		<Router>
			<App />
		</Router>
	);
};

export default AppWrapper;
