import React, { useState, useEffect } from "react";
import { Badge, Button, List, Drawer, Typography, message } from "antd";
import { BellOutlined } from "@ant-design/icons";
import {
	getNotifications,
	markNotificationAsRead,
} from "../../services/apiMethods";

const { Text } = Typography;

const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const [isDrawerVisible, setIsDrawerVisible] = useState(false);

	useEffect(() => {
		fetchNotifications();
	}, []);

	const fetchNotifications = async () => {
		try {
			const data = await getNotifications();
			setNotifications(data);
		} catch (error) {
			console.error("Ошибка при загрузке уведомлений:", error);
			message.error("Ошибка при загрузке уведомлений.");
		}
	};

	const handleMarkAsRead = async (id) => {
		try {
			await markNotificationAsRead(id);
			setNotifications((prev) => prev.filter((notif) => notif.id !== id));
			message.success("Уведомление отмечено как прочитанное.");
		} catch (error) {
			console.error("Ошибка при обновлении уведомления:", error);
			message.error("Ошибка при обновлении уведомления.");
		}
	};

	return (
		<div>
			<Badge count={notifications.length} offset={[10, 0]}>
				<Button
					type="text"
					icon={<BellOutlined style={{ color: "white" }} />}
					onClick={() => setIsDrawerVisible(true)}
				/>
			</Badge>

			<Drawer
				title="Уведомления"
				visible={isDrawerVisible}
				onClose={() => setIsDrawerVisible(false)}
				width={300}
			>
				<List
					dataSource={notifications}
					renderItem={(item) => (
						<List.Item
							actions={[
								<Button type="link" onClick={() => handleMarkAsRead(item.id)}>
									Прочитать
								</Button>,
							]}
						>
							<List.Item.Meta
								title={<Text>{`Лид #${item.leadId} от ${item.sender}`}</Text>}
								description={`Получен ${new Date(
									item.createdAt
								).toLocaleString()}`}
							/>
						</List.Item>
					)}
				/>
			</Drawer>
		</div>
	);
};

export default Notifications;
