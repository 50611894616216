import React, { useState } from 'react';
import { Upload, Button, InputNumber, message, List } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { preprocessLeads } from '../../services/apiMethods'; 
// ^ Используем вашу функцию из apiMethods

const LeadPreprocess = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [partsCount, setPartsCount] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [stats, setStats] = useState(null);

  // Обработка выбора файла через antd Upload
  const handleFileChange = (info) => {
    const file = info.file.originFileObj || info.file;
    if (file) {
      setSelectedFile(file);
      message.success(`Файл "${file.name}" выбран.`);
    } else {
      message.error('Ошибка при выборе файла.');
    }
  };

  // Запуск предобработки
  const handlePreprocess = async () => {
    if (!selectedFile) {
      message.error('Пожалуйста, выберите файл.');
      return;
    }
    if (partsCount < 1) {
      message.error('Количество частей должно быть больше 0.');
      return;
    }

    setIsProcessing(true);

    try {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('partsCount', partsCount);

      // Вызываем preprocessLeads из apiMethods
      const data = await preprocessLeads(formData);
      // Ожидаем, что data = { files: [...], stats: {...} }
      console.log('Response data:', data);

      if (data && Array.isArray(data.files)) {
        setDownloadLinks(data.files);
        setStats(data.stats);
        message.success('Файл успешно обработан.');
      } else {
        message.error('Некорректный формат ответа от сервера.');
      }
    } catch (error) {
      console.error('Ошибка обработки файла:', error);
      message.error('Ошибка обработки файла.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Скачивание файла
  const handleDownload = (fileName) => {
    const timestamp = Date.now();
    // Перенаправляем браузер на маршрут скачивания
    window.location.href = `/api/download/${fileName}?_t=${timestamp}`;
  };

  return (
    <div style={{ margin: 20 }}>
      <h2>Предварительная обработка лидов</h2>
      
      <Upload
        beforeUpload={() => false}
        onChange={handleFileChange}
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Выберите файл</Button>
      </Upload>

      {selectedFile && (
        <div style={{ marginTop: 10 }}>
          <strong>Выбран файл:</strong> {selectedFile.name}
        </div>
      )}

      <div style={{ marginTop: 20 }}>
        <span>Количество частей:</span>{' '}
        <InputNumber
          min={1}
          value={partsCount}
          onChange={setPartsCount}
          style={{ marginLeft: 10 }}
        />
      </div>

      <Button
        type="primary"
        disabled={isProcessing}
        onClick={handlePreprocess}
        style={{ marginTop: 20 }}
      >
        {isProcessing ? 'Обработка...' : 'Обработать файл'}
      </Button>

      {stats && (
        <div style={{ marginTop: 20 }}>
          <h3>Статистика:</h3>
          {/* Ниже блоки зависят от того, какие поля реально возвращает сервер! */}
          <p>Всего строк: {stats.totalRows}</p>
          {/* Если у вас только "duplicateCount", меняйте ниже */}
          {typeof stats.duplicateCountLocal !== 'undefined' && (
            <p>Дубликатов в файле: {stats.duplicateCountLocal}</p>
          )}
          {typeof stats.duplicateCountDB !== 'undefined' && (
            <p>Дубликатов в базе: {stats.duplicateCountDB}</p>
          )}
          {typeof stats.skippedCount !== 'undefined' && (
            <p>Пропущенных (пуст / нечисло): {stats.skippedCount}</p>
          )}
          {/* Если вы возвращаете, например, "duplicateCount" вместо "duplicateCountLocal",
              скорректируйте эти поля соответственно */}
        </div>
      )}

      {downloadLinks.length > 0 && (
        <div style={{ marginTop: 20 }}>
          <h3>Ссылки для скачивания:</h3>
          <List
            bordered
            dataSource={downloadLinks}
            renderItem={(fileName) => (
              <List.Item>
                <Button
                  icon={<DownloadOutlined />}
                  type="link"
                  onClick={() => handleDownload(fileName)}
                >
                  {fileName}
                </Button>
              </List.Item>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default LeadPreprocess;
