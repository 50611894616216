import React from 'react';
import { Layout, Menu, Dropdown, Avatar, Switch } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  DashboardOutlined,
  FileTextOutlined,
  TeamOutlined,
  ImportOutlined,
  SettingOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import logo from './images/logo.svg';

import Notifications from '../Shared/Notifications';

const { Header } = Layout;

const AppHeader = ({ user, onLogout, darkMode, toggleTheme }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const handleMainClick = () => {
    if (user?.role === 'admin') {
      navigate('/admin');
    } else if (user?.role === 'closer') {
      navigate('/closer');
    } else if (user?.role === 'employee') {
      navigate('/employee');
    }
  };

  const getSelectedKey = () => {
    if (user?.role === 'admin') {
      if (location.pathname.startsWith('/admin')) return ['admin'];
    } else if (user?.role === 'closer') {
      if (location.pathname.startsWith('/closer')) return ['closer'];
    } else if (user?.role === 'employee') {
      if (location.pathname.startsWith('/employee')) return ['employee'];
    }
    return [];
  };

  const profileMenu = (
    <Menu
      items={[
        {
          label: 'Профиль',
          key: 'profile',
          icon: <UserOutlined />,
          onClick: () => navigate('/profile'),
        },
        {
          label: 'Выйти',
          key: 'logout',
          icon: <LogoutOutlined />,
          onClick: handleLogout,
        },
      ]}
    />
  );

  const menuItems = [
    {
      label: 'Главная',
      key: 'home',
      icon: <DashboardOutlined />,
      onClick: handleMainClick
    },
    ...(user?.role === 'admin' ? [
      {
        label: 'Список Лидов',
        key: 'lead-list',
        icon: <FileTextOutlined />,
        onClick: () => navigate('/lead-list')
      },
      {
        label: 'Статистика',
        key: 'statistics',
        icon: <TeamOutlined />,
        children: [
          { label: 'Статистика Команд', key: 'team-stat', icon: <TeamOutlined />, onClick: () => navigate('/team-stat') },
          { label: 'Статистика Сотрудников', key: 'lead-stat', icon: <FileTextOutlined />, onClick: () => navigate('/lead-stat') },
          { label: 'Активность пользователей', key: 'online', icon: <FileTextOutlined />, onClick: () => navigate('/online') }
        ]
      },
      {
        label: 'Управление',
        key: 'admin-management',
        icon: <SettingOutlined />,
        children: [
          { label: 'IP Whitelist', key: 'whitelist', icon: <SettingOutlined />, onClick: () => navigate('/admin/ip-whitelist') },
          { label: 'Управление Командами', key: 'team-management', icon: <TeamOutlined />, onClick: () => navigate('/team-management') },
          { label: 'Управление Базами', key: 'base', icon: <TeamOutlined />, onClick: () => navigate('/base') },
          { label: 'Импорт Лидов', key: 'lead-import', icon: <ImportOutlined />, onClick: () => navigate('/admin/lead-import') },
          { label: 'Предварительная обработка импорта', key: 'lead-preprocess', icon: <ImportOutlined />, onClick: () => navigate('/admin/lead-preprocess') },
        ]
      },
    ] : []),
    ...(user?.role === 'closer' ? [
      { label: 'Панель Клоузера', key: 'closer', icon: <TeamOutlined />, onClick: () => navigate('/closer') },
      {
        label: 'Список Лидов',
        key: 'lead-list',
        icon: <FileTextOutlined />,
        onClick: () => navigate('/lead-list')
      },
      {
        label: 'Статистика',
        key: 'statistics',
        icon: <TeamOutlined />,
        children: [
          { label: 'Статистика Команд', key: 'team-stat', icon: <TeamOutlined />, onClick: () => navigate('/team-stat') },
          { label: 'Статистика Сотрудников', key: 'lead-stat', icon: <FileTextOutlined />, onClick: () => navigate('/lead-stat') },
          { label: 'Активность пользователей', key: 'online', icon: <FileTextOutlined />, onClick: () => navigate('/online') }
        ]
      },
    ] : []),
    ...(user?.role === 'employee' ? [
      { label: 'Панель Сотрудника', key: 'employee', icon: <TeamOutlined />, onClick: () => navigate('/employee') }
    ] : []),
  ];

  return (
    <Header style={{ padding: '0 20px', backgroundColor: '#001529', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="logo" style={{ margin: '0 auto' }}>
          <img src={logo} alt="Logo" style={{ width: '80px', marginTop: '8px' }} />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={getSelectedKey()}
          defaultSelectedKeys={['home']}
          style={{ flex: 1 }}
          items={menuItems}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      <Notifications />
        <Switch
          checked={darkMode}
          onChange={toggleTheme}
          checkedChildren="Dark"
          unCheckedChildren="Light"
        />
        <Dropdown overlay={profileMenu} placement="bottomRight" trigger={['click']}>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
          />
        </Dropdown>
      </div>
    </Header>
  );
};

export default AppHeader;
