import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, message, Space } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { getWhitelistedIps, addWhitelistedIp, deleteWhitelistedIp } from '../../services/apiMethods';

const WhitelistManagement = () => {
  const [ips, setIps] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    fetchIps();
  }, []);

  const fetchIps = async () => {
    try {
      const data = await getWhitelistedIps();
      setIps(data);
    } catch {
      message.error('Ошибка при загрузке IP-адресов');
    }
  };

  const handleAddIp = async (values) => {
    try {
      await addWhitelistedIp(values);
      message.success('IP успешно добавлен в вайтлист');
      setIsModalVisible(false);
      fetchIps();
    } catch {
      message.error('Ошибка при добавлении IP в вайтлист');
    }
  };

  const handleDeleteIp = async (id) => {
    try {
      await deleteWhitelistedIp(id);
      message.success('IP удалён из вайтлиста');
      fetchIps();
    } catch {
      message.error('Ошибка при удалении IP');
    }
  };

  return (
    <div style={{ padding: 24, background: '#fff', borderRadius: 8 }}>
      <Space style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 24 }}>
        <h2 style={{ margin: 0, fontWeight: 600 }}>Управление IP-вайтлистом</h2>
        <Button type="primary" icon={<PlusOutlined />} size="large" onClick={() => setIsModalVisible(true)}>
          Добавить IP
        </Button>
      </Space>
      <Table
        dataSource={ips}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
        columns={[
          { title: 'IP адрес', dataIndex: 'ipAddress', key: 'ipAddress', align: 'left' },
          {
            title: 'Действия',
            key: 'actions',
            align: 'center',
            render: (text, record) => (
              <Button
                type="text"
                icon={<DeleteOutlined style={{ color: 'red', fontSize: 16 }} />}
                onClick={() => handleDeleteIp(record.id)}
              />
            ),
          },
        ]}
      />
      <Modal
        title={<h3 style={{ margin: 0 }}>Добавить IP</h3>}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        width={500}
      >
        <Form onFinish={handleAddIp} layout="vertical">
          <Form.Item
            name="ipAddress"
            label="IP-адрес"
            rules={[{ required: true, message: 'Пожалуйста, введите IP-адрес' }]}
          >
            <Input placeholder="Введите IP-адрес" size="large" />
          </Form.Item>
          <Button type="primary" htmlType="submit" block size="large">
            Добавить
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default WhitelistManagement;
