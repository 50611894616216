import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, notification } from 'antd';
import { getTeams } from '../../services/apiMethods'; // Импорт метода для получения команд

const { Option } = Select;

const UserForm = ({ onAddUser }) => {
  const [form] = Form.useForm();
  const [generatedPassword, setGeneratedPassword] = useState('');
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    fetchTeams(); // Загружаем команды с сервера
  }, []);

  const fetchTeams = async () => {
    try {
      const data = await getTeams();
      setTeams(data); // Сохраняем id и name команд
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: 'Ошибка при загрузке команд',
      });
    }
  };

  // Функция генерации пароля
  const generatePassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()';
    let password = '';
    for (let i = 0; i < 10; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setGeneratedPassword(password);
    form.setFieldsValue({ password });
    notification.success({
      message: 'Пароль сгенерирован',
      description: `Ваш сгенерированный пароль: ${password}`,
    });
  };

  const onFinish = (values) => {
    onAddUser(values);
    notification.success({
      message: 'Пользователь добавлен',
      description: `Пользователь ${values.username} был успешно добавлен с ролью ${values.role}.`,
    });
    form.resetFields(); // Сброс формы
  };

  return (
    <Form
      form={form}
      name="add-user"
      onFinish={onFinish}
      layout="vertical"
      style={{ maxWidth: 400 }}
    >
      <Form.Item
        name="username"
        label="Имя пользователя"
        rules={[
          { required: true, message: 'Пожалуйста, введите имя пользователя!' },
          {
            pattern: /^[^\s]+$/,
            message: 'Имя пользователя не должно содержать пробелов!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="password"
        label="Пароль"
        rules={[{ required: true, message: 'Пожалуйста, введите пароль или сгенерируйте его!' }]}
      >
        <Input.Password value={generatedPassword} />
      </Form.Item>

      <Form.Item>
        <Button type="default" onClick={generatePassword}>
          Сгенерировать пароль
        </Button>
      </Form.Item>

      <Form.Item
        name="role"
        label="Роль"
        rules={[{ required: true, message: 'Пожалуйста, выберите роль!' }]}
      >
        <Select placeholder="Выберите роль" onChange={() => form.resetFields(['teamId'])}>
          <Option value="admin">Администратор</Option>
          <Option value="closer">Клоузер</Option>
          <Option value="employee">Сотрудник</Option>
        </Select>
      </Form.Item>

      {/* Условное отображение поля выбора команды только для неадминистраторов */}
      {form.getFieldValue('role') !== 'admin' && (
        <Form.Item
          name="teamId"
          label="Команда"
          rules={[{ required: true, message: 'Пожалуйста, выберите команду!' }]}
        >
          <Select placeholder="Выберите команду">
            {teams.map((team) => (
              <Option key={team.id} value={team.id}>
                {team.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Добавить пользователя
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
