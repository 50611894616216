import apiService from './apiService';






export const assignLeadsToAll = async () => {
  try {
    const response = await apiService.post('/leads/assign-unassigned-all');
    return response.data;
  } catch (error) {
    throw new Error('Ошибка при назначении лидов');
  }
};

export const addUser = async (newUser) => {
  const response = await apiService.post('/users', newUser);
  return response.data;
};
// Получение списка пользователей
export const getUsers = async (params) => {
  const response = await apiService.get('/users', { params });
  return response.data;
};


// Получение списка команд
export const getTeams = async () => {
  const response = await apiService.get('/teams');
  return response.data;
};

// Начало импорта лидов
export const startLeadImport = async (formData) => {
  const response = await apiService.post('/import-leads', formData);
  return response.data;
};

// Проверка статуса задачи
export const checkTaskStatus = async (taskId) => {
  const response = await apiService.get(`/task-status/${taskId}`);
  return response.data;
};

  
  // Получение статистики пользователя
  export const getUserStatistics = async (params) => {
    const response = await apiService.get('/user/statistics', { params });
    return response.data;
  };
  
  // Получение пользователей без команды
  export const getUsersWithoutTeam = async () => {
    const response = await apiService.get('/users/no-team');
    return response.data;
  };
  
  // Получение пользователей в команде
  export const getUsersInTeam = async (teamId) => {
    const url = teamId === 'no-team' ? '/users/no-team' : `/teams/${teamId}/users`;
    const response = await apiService.get(url);
    return response.data;
  };
  
  // Обновление команды
  export const updateTeam = async (id, values) => {
    await apiService.put(`/teams/${id}`, values);
  };
  
  // Удаление команды
  export const deleteTeam = async (id) => {
    await apiService.delete(`/teams/${id}`);
  };
  
  // Назначение команды пользователю
  export const assignTeamToUser = async (userId, teamId) => {
    await apiService.put(`/users/${userId}/team`, { teamId });
  };

  
  // Получение статистики команды
  export const getTeamStatistics = async (team, startDate, endDate) => {
    const response = await apiService.get('/team/statistics', {
      params: {
        team,
        startDate,
        endDate,
      },
    });
    return response.data;
  };
  
  // Обновление роли пользователя
  export const updateUserRole = async (userId, newRole) => {
    await apiService.put(`/users/${userId}/role`, { role: newRole });
  };
  
  // Обновление команды пользователя
  export const updateUserTeam = async (userId, newTeamId) => {
    await apiService.put(`/users/${userId}/team`, { teamId: newTeamId });
  };
  
  // Обновление статуса активности пользователя
  export const updateUserActiveStatus = async (userId, isActive) => {
    const response = await apiService.put(`/users/${userId}/activate`, { isActive });
    return response.data; // Важно: возвращаем data из ответа
  };
  
  
  // Обновление блокировки получения лидов
  export const updateUserLeadBlockStatus = async (userId, isBlockedFromLeads) => {
    const response = await apiService.put(`/users/${userId}/block-leads`, { isBlockedFromLeads });
    return response.data; // Важно: возвращаем только data
  };
  
  
  // Присвоение лида пользователю
  export const assignLeadToUser = async (username, teamId) => {
    const response = await apiService.post('/leads/assign', { assignedTo: username, teamId });
    return response.data;
  };
  
  // Получение списка whitelisted IP
export const getWhitelistedIps = async () => {
    const response = await apiService.get('/whitelisted-ips');
    return response.data;
  };
  
  // Добавление нового IP в whitelist
  export const addWhitelistedIp = async (values) => {
    await apiService.post('/whitelisted-ips', values);
  };
  
  // Удаление IP из whitelist
  export const deleteWhitelistedIp = async (id) => {
    await apiService.delete(`/whitelisted-ips/${id}`);
  };

  export const deleteUser = async (id) => {
    const response = await apiService.delete(`/users/${id}`);
    return response.data;
  };
  
  

  // Получение данных текущего пользователя
export const getCurrentUser = async (username) => {
    const response = await apiService.get('/users/me', {
      params: { username },
    });
    return response.data;
  };
  
  // Обновление данных текущего пользователя
  export const updateUserProfile = async (id, values) => {
    const response = await apiService.put(`/users/${id}`, values);
    return response.data;
  };

  // Получение списка лидов
  export const getLeads = async (params) => {
    try {
      const response = await apiService.get('/leads', { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  
  
  // Получение следующего лида
  export const getNextLead = async (assignedTo) => {
    const response = await apiService.get('/leads/next', {
      params: { assignedTo },
    });
    return response.data;
  };
  
  // Обновление лида
  export const updateLead = async (leadId, values) => {
    const response = await apiService.put(`/leads/${leadId}`, values);
    return response.data;
  };
  
  // Обновление активности лида
  export const updateLeadActivity = async (leadId, isActive) => {
    await apiService.put(`/leads/${leadId}/active`, { isActive });
  };
  
  export const importLeads = async (formData) => {
    const response = await apiService.post('/import-leads', formData);
    return response.data;
  };

  export const validateToken = async () => {
    const response = await apiService.get('/auth/validate-token');
    return response.data;
  };
  

  // Получение данных текущего пользователя через токен (новый маршрут)
export const getCurrentUserFromToken = async () => {
  const response = await apiService.get('/auth/current-user');
  return response.data;
};
  
export const getCurrentUser2 = async () => {
  const response = await apiService.get('/auth/current-user');
  return response.data;
};


export const addNewTeam = async (values) => {
  const response = await apiService.post('/teams', values);  // Отправляем запрос на создание команды
  return response.data;
};

export const getUsersInfo = async (userIds) => {
  const response = await apiService.post('/users/info', { userIds });
  return response.data;
};

export const fetchOnlineUsers = async () => {
  const response = await apiService.get('/users/online'); // Используем новый эндпоинт
  return response.data;
};

export const fetchDatabases = async (teamName) => {
  const response = await apiService.get('/databases', { params: { teamName } });
  return response.data;
};


export const getActiveDatabaseForTeam = async (teamName) => {
	const response = await apiService.get('/databases/active', { params: { teamName } });
	return response.data; // { activeSource: 'source1' }
};

export const setActiveDatabaseForTeam = async (teamName, source) => {
	await apiService.post('/databases/active', { teamName, source });
};

export const getUnassignedCounts = async () => {
  const response = await apiService.get('/databases/unassigned-count');
  return response.data;
}


export const preprocessLeads = async (formData) => {
  const response = await apiService.post('/preprocess-leads', formData);
  return response.data; // Возвращает список файлов
};


export const shareLead = async (leadId, sharedTo) => {
  const response = await apiService.post(`/leads/${leadId}/share`, { sharedTo });
  return response.data;
};

export const getNotifications = async () => {
  const response = await apiService.get('/notifications');
  return response.data;
};

export const markNotificationAsRead = async (id) => {
  const response = await apiService.put(`/notifications/${id}/read`);
  return response.data;
};

export const reassignDatabaseToTeam = async (source, newTeamName) => {
  const response = await apiService.post('/databases/reassign', { source, newTeamName });
  return response.data;
};


export const fetchDatabasesWithTeams = async () => {
  const response = await apiService.get('/databases/with-teams'); // Эндпоинт для получения баз с командами
  return response.data;
};