import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import moment from "moment";
import {
  getLeads,
  getNextLead,
  updateLead,
  updateLeadActivity,
  getUsers,
  getTeams,
  shareLead,
} from "../../services/apiMethods";

const { Option } = Select;

const statusMap = [
  { id: 1, name: "Новый" },
  { id: 2, name: "Ребенок" },
  { id: 3, name: "НБТ" },
  { id: 4, name: "Нет средств" },
  { id: 5, name: "Умник" },
  { id: 6, name: "Срез" },
  { id: 7, name: "Перезвон" },
  { id: 8, name: "Поляк" },
  { id: 9, name: "Списали" },
  { id: 10, name: "Срез на закрытии" },
  { id: 11, name: "Закрыт" },
];

const LeadList = ({ user }) => {
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentLead, setCurrentLead] = useState(null);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [isFetchingNextLead, setIsFetchingNextLead] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  
  // Управление пагинацией
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  // Фильтры и сортировка
  const [filters, setFilters] = useState({});
  const [sorter, setSorter] = useState({});

  const [sourceOptions, setSourceOptions] = useState([]);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  // Загружаем команды и сотрудников при первом рендере
  useEffect(() => {
    const loadInitialData = async () => {
      await fetchTeamsData();
      await fetchEmployeeOptions();
      // После того, как команды и сотрудники загружены, можем грузить лиды
      fetchLeads(pagination, filters, sorter);
    };
    loadInitialData();
  }, [user]); // при изменении user обновим

  // ========================================
  // Функция для запроса списка лидов
  // ========================================
  const fetchLeads = async (
    paginationParams = pagination,
    filterParams = filters,
    sortParams = sorter
  ) => {
    setIsLoading(true);
    try {
      const sortField = sortParams.field || "updatedAt";
      const sortOrder = sortParams.order === "ascend" ? "ASC" : "DESC";

      // Подготовим объект фильтров
      const preparedFilters = prepareFilters(filterParams);

      const params = {
        role: user.role,
        ...(user.role === "employee" && { assignedTo: user.username }),
        ...(user.role === "closer" && { team: user.team }),
        page: paginationParams.current,
        pageSize: paginationParams.pageSize,
        sortField,
        sortOrder,
        ...preparedFilters,
      };

      const data = await getLeads(params);

      // Ограничиваем источники для employee и closer, чтобы в фильтрации по "Источник" 
      // не показывать лишние значения
      let filteredSources = data.sources || [];
      if (user.role === "employee") {
        filteredSources = filteredSources.filter((source) =>
          data.leads.some((lead) => lead.source === source && lead.assignedTo === user.username)
        );
      } else if (user.role === "closer") {
        filteredSources = filteredSources.filter((source) =>
          data.leads.some((lead) => lead.source === source && lead.team === user.team)
        );
      }

      setLeads(data.leads);
      setPagination({
        current: data.page,
        pageSize: data.pageSize,
        total: data.total,
      });
      setSourceOptions(filteredSources);
    } catch (error) {
      console.error("Ошибка загрузки лидов:", error);
      message.error(error.response?.data?.error || "Ошибка загрузки лидов");
    } finally {
      setIsLoading(false);
    }
  };

  // ========================================
  // Вспомогательная функция для подготовки фильтров
  // ========================================
  const prepareFilters = (filters) => {
    const result = {};

    // Обрабатываем диапазон дат (updatedAt)
    if (filters.updatedAt) {
      const dateRange = filters.updatedAt.split(",");
      if (dateRange.length === 2) {
        result.startDate = dateRange[0];
        result.endDate = dateRange[1];
      }
    }

    // Обрабатываем фильтр по статусу
    if (filters.statusId) {
      result.status = Array.isArray(filters.statusId)
        ? filters.statusId.map(Number)
        : [Number(filters.statusId)];
    }

    // Обрабатываем фильтр по команде
    if (filters.team) {
      result.team = filters.team;
    }

    // Прочие фильтры (например, комментарий и т.д.)
    Object.keys(filters).forEach((key) => {
      if (!["updatedAt", "statusId", "team"].includes(key) && filters[key]) {
        result[key] = filters[key];
      }
    });

    return result;
  };

  // ========================================
  // Загрузка команд
  // ========================================
  const fetchTeamsData = async () => {
    if (teamOptions.length > 0) return; // чтобы не грузить повторно
    try {
      const teams = await getTeams();
      setTeamOptions(teams);
    } catch {
      message.error("Ошибка при загрузке команд");
    }
  };

  // ========================================
  // Загрузка пользователей-сотрудников
  // ========================================
  const fetchEmployeeOptions = async () => {
    if (employeeOptions.length > 0) return;
    try {
      const users = await getUsers({ role: "employee" });
      setEmployeeOptions(users);
    } catch {
      message.error("Ошибка при загрузке сотрудников");
    }
  };

  // ========================================
  // Запрос следующего лида (для роли employee)
  // ========================================
  const fetchNextLead = async () => {
    if (isFetchingNextLead) return;
    setIsFetchingNextLead(true);

    try {
      const data = await getNextLead(user.username);

      if (data) {
        message.success("Назначен новый лид.");
        // Можно подгрузить лиды заново, или добавлять вручную в список
        // В данном примере проще пересобрать список
        fetchLeads();
      } else {
        message.info("Нет доступных новых лидов.");
      }
    } catch (error) {
      console.error("Ошибка при получении нового лида:", error);
      message.error("Ошибка при получении нового лида.");
    } finally {
      setIsFetchingNextLead(false);
    }
  };

  // ========================================
  // Обновление лида (при сабмите формы)
  // ========================================
  const handleUpdate = async (values) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      const isStatusChangedFromNew =
        currentLead.statusId === 1 && values.status !== 1;

      const payload = {
        ...values,
        statusId: values.status,
        team: values.team,
      };

      await updateLead(currentLead.id, payload);

      message.success("Лид успешно обновлен.");
      await fetchLeads();

      // Если лид был новым и ему поменяли статус, а пользователь — employee,
      // то запрашиваем нового лида
      if (isStatusChangedFromNew && user.role === "employee") {
        await fetchNextLead();
      }

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Ошибка при обновлении лида:", error);
      message.error("Ошибка при обновлении лида.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // ========================================
  // Открыть форму редактирования
  // ========================================
  const showEditModal = (lead) => {
    setCurrentLead(lead);
    form.setFieldsValue({
      ...lead,
      status: lead.statusId,
      team: lead.team,
      dateOfBirth: lead.dateOfBirth ? moment(lead.dateOfBirth) : null,
    });
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  // ========================================
  // Логика для фильтров колонок
  // ========================================
  const getColumnSelectProps = (dataIndex, options) => ({
    filters: options.map((option) => ({
      text: option.name || option.username || option,
      value: option.id || option.username || option,
    })),
    onFilter: (value, record) => record[dataIndex] === value,
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Поиск ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={confirm}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={confirm}
          style={{ width: "100%", marginBottom: 8 }}
        >
          Поиск
        </Button>
        <Button onClick={clearFilters} style={{ width: "100%" }}>
          Сброс
        </Button>
      </div>
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : "",
  });

  // ========================================
  // Открыть модалку "Поделиться лидом"
  // ========================================
  const openShareModal = (lead) => {
    setCurrentLead(lead);
    setIsShareModalVisible(true);
  };

  // Передача лида другому пользователю
  const handleShareLead = async (leadId, sharedTo) => {
    try {
      await shareLead(leadId, sharedTo);
      message.success(`Лид успешно передан пользователю ${sharedTo}`);

      // После передачи обновим список лидов
      fetchLeads();

      // Если передали лид, который был "Новым" (statusId === 1), то employee 
      // может запросить себе следующий лид
      const sharedLead = leads.find((lead) => lead.id === leadId);
      if (sharedLead && sharedLead.statusId === 1) {
        await fetchNextLead();
      }

      setIsShareModalVisible(false);
    } catch (error) {
      console.error("Ошибка при передаче лида:", error);
      message.error("Ошибка при передаче лида");
    }
  };

  const countryFlags = {
    Польша: "🇵🇱",
    США: "🇺🇸",
    Германия: "🇩🇪",
    Франция: "🇫🇷",
    // Добавьте другие страны при необходимости
  };

  // ========================================
  // Описание колонок таблицы
  // ========================================
  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...getColumnSearchProps("name"),
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => {
        const formattedPhone = phone.startsWith("+") ? phone : `+${phone}`;
        return (
          <a href={`sip:${formattedPhone}`} target="_blank" rel="noopener noreferrer">
            {formattedPhone}
          </a>
        );
      },
      ...getColumnSearchProps("phone"),
    },
    {
      title: "Команда",
      dataIndex: "team",
      key: "team",
      ...getColumnSelectProps(
        "team",
        teamOptions.map((t) => ({ id: t.name, name: t.name }))
      ),
    },
    {
      title: "Статус",
      dataIndex: "statusId",
      key: "status",
      render: (statusId) => {
        const status = statusMap.find((s) => s.id === statusId);
        return status ? status.name : "Не указан";
      },
      ...getColumnSelectProps("statusId", statusMap),
    },
    {
      title: "Ответственный",
      dataIndex: "assignedTo",
      key: "assignedTo",
      render: (assignedTo) => assignedTo || "Не назначен",
      ...getColumnSearchProps("assignedTo"),
    },
    {
      title: "Страна",
      dataIndex: "country",
      key: "country",
      render: (country) =>
        countryFlags[country] ? (
          <span>{countryFlags[country]}</span>
        ) : (
          <span>{country || "Не указано"}</span>
        ),
    },
    {
      title: "Источник",
      dataIndex: "source",
      key: "source",
      filters: sourceOptions.map((source) => ({
        text: source,
        value: source,
      })),
      onFilter: (value, record) => record.source === value,
      render: (source) => source || "Не указано",
    },
    {
      title: "Дата обновления",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      render: (text) => moment(text).format("DD.MM.YYYY HH:mm"),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <DatePicker.RangePicker
            format="DD.MM.YYYY"
            onChange={(dates) => {
              if (dates) {
                const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
                setSelectedKeys([formattedDates.join(",")]);
              } else {
                setSelectedKeys([]);
              }
            }}
            style={{ marginBottom: 8 }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            style={{ width: "100%", marginBottom: 8 }}
          >
            Применить
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            style={{ width: "100%" }}
          >
            Сбросить
          </Button>
        </div>
      ),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
      ...getColumnSearchProps("comment"),
    },
    {
      title: "Действия",
      key: "actions",
      render: (_, record) => (
        <>
          <Button style={{ marginRight: 8 }} onClick={() => showEditModal(record)}>
            Редактировать
          </Button>
          <Button onClick={() => openShareModal(record)}>
            Поделиться
          </Button>
        </>
      ),
    },
  ];

  // ========================================
  // Единый обработчик для пагинации, сортировки, фильтров
  // ========================================
  const handleTableChange = (tablePagination, tableFilters, tableSorter) => {
    // 1. Собираем новые фильтры
    const updatedFilters = { ...filters, ...tableFilters };

    // Если это наш кастомный фильтр по updatedAt (диапазон дат) — превратим массив в строку
    if (Array.isArray(tableFilters.updatedAt)) {
      updatedFilters.updatedAt = tableFilters.updatedAt.join(",");
    } else if (tableFilters.updatedAt === undefined) {
      updatedFilters.updatedAt = undefined;
    }

    // 2. Сохраняем новые значения в стейт
    setFilters(updatedFilters);
    setSorter(tableSorter);

    // 3. Обновляем пагинацию
    setPagination({
      ...pagination,
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
      // total можно не трогать – оно придёт с сервера заново
    });

    // 4. Делаем запрос
    fetchLeads(tablePagination, updatedFilters, tableSorter);
  };

  return (
    <div>
      <h2>Лиды</h2>

      {user.role === "employee" && (
        <Button
          type="primary"
          onClick={fetchNextLead}
          loading={isFetchingNextLead}
          style={{ marginBottom: 16 }}
        >
          Запросить лида
        </Button>
      )}

      <Table
        dataSource={leads}
        columns={columns}
        rowKey="id"
        loading={isLoading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          // убрали onChange тут — всё обрабатываем через onChange таблицы
        }}
        // ЕДИНЫЙ коллбэк для всех изменений (пагинация, сортировка, фильтры)
        onChange={handleTableChange}
      />

      {/* Модальное окно редактирования */}
      <Modal
        title="Редактировать Лид"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width="100%"
        style={{ top: 20 }}
      >
        <Form layout="vertical" form={form} onFinish={handleUpdate}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
            <Form.Item
              name="name"
              label="Имя"
              rules={[{ required: true }]}
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="phone"
              label="Телефон"
              rules={[{ required: true }]}
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="status"
              label="Статус"
              style={{ flex: "1 1 30%" }}
            >
              <Select>
                {statusMap.map((status) => (
                  <Option key={status.id} value={status.id}>
                    {status.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* Поля для ролей кроме employee */}
            {user.role !== "employee" && (
              <>
                <Form.Item
                  name="team"
                  label="Команда"
                  style={{ flex: "1 1 30%" }}
                >
                  <Select>
                    {teamOptions.map((team) => (
                      <Option key={team.name} value={team.name}>
                        {team.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="assignedTo"
                  label="Ответственный сотрудник"
                  style={{ flex: "1 1 30%" }}
                >
                  <Select
                    showSearch
                    placeholder="Выберите или введите имя"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option?.children?.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {employeeOptions.map((employee) => (
                      <Option key={employee.username} value={employee.username}>
                        {employee.username}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            <Form.Item
              name="dateOfBirth"
              label="Дата рождения"
              style={{ flex: "1 1 30%" }}
            >
              <DatePicker format="DD.MM.YYYY" />
            </Form.Item>
            <Form.Item
              name="app"
              label="АПП"
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="bank"
              label="БАНК"
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="operation"
              label="ОПЕРАЦИЯ"
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="balance"
              label="БАЛАНС"
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="currency"
              label="ВАЛЮТКА"
              style={{ flex: "1 1 30%" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="comment"
              label="Комментарий"
              style={{ flex: "1 1 100%" }}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </div>
          <Button type="primary" htmlType="submit" block loading={isSubmitting}>
            Сохранить
          </Button>
        </Form>
      </Modal>

      {/* Модальное окно "Поделиться лидом" */}
      <Modal
        title="Передача лида"
        open={isShareModalVisible}
        onCancel={() => setIsShareModalVisible(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={({ sharedTo }) => handleShareLead(currentLead.id, sharedTo)}
        >
          <Form.Item
            name="sharedTo"
            label="Выберите пользователя"
            rules={[
              { required: true, message: "Пожалуйста, выберите пользователя" },
            ]}
          >
            <Select
              showSearch
              placeholder="Введите имя пользователя"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.label?.toLowerCase().includes(input.toLowerCase())
              }
              options={employeeOptions.map((user) => ({
                label: user.username,
                value: user.username,
              }))}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit" block>
            Поделиться
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default LeadList;
