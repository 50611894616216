import React, { useState, useEffect } from 'react';
import { Table, Button, Select, Switch, Input, message, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  getTeams,
  updateUserRole,
  updateUserTeam,
  updateUserActiveStatus,
  updateUserLeadBlockStatus,
  assignLeadToUser,
} from '../../services/apiMethods';

const { Option } = Select;

const UserTable = ({ initialUsers = [], onDeleteUser }) => {
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchFilters, setSearchFilters] = useState({
    username: '',
    role: '',
    teamId: '',
    isActive: '',
    isBlockedFromLeads: '',
  });
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (initialUsers.length > 0) {
      setAllUsers(initialUsers);
      setFilteredUsers(initialUsers.slice(0, pageSize));
      setIsLoading(false);
    }
  }, [initialUsers]);

  useEffect(() => {
    fetchTeamsData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [searchFilters, currentPage, pageSize]);

  const fetchTeamsData = async () => {
    try {
      const data = await getTeams();
      setTeamOptions(data || []);
    } catch (error) {
      message.error('Ошибка при загрузке команд');
    }
  };

  const applyFilters = () => {
    const filtered = allUsers.filter((user) =>
      user.username.toLowerCase().includes(searchFilters.username.toLowerCase()) &&
      user.role.toLowerCase().includes(searchFilters.role.toLowerCase()) &&
      (searchFilters.teamId ? user.teamId === parseInt(searchFilters.teamId, 10) : true) &&
      (searchFilters.isActive !== ''
        ? user.isActive === (searchFilters.isActive === 'true')
        : true) &&
      (searchFilters.isBlockedFromLeads !== ''
        ? user.isBlockedFromLeads === (searchFilters.isBlockedFromLeads === 'true')
        : true)
    );
    setFilteredUsers(filtered.slice((currentPage - 1) * pageSize, currentPage * pageSize));
  };

  const handleSearch = (value, column) => {
    setSearchFilters((prev) => ({
      ...prev,
      [column]: value,
    }));
    setCurrentPage(1); // Сброс на первую страницу при поиске
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      await updateUserRole(userId, newRole);
      message.success('Роль пользователя успешно обновлена');
    } catch {
      message.error('Ошибка при обновлении роли пользователя');
    }
  };

  const handleTeamChange = async (userId, newTeamId) => {
    try {
      await updateUserTeam(userId, newTeamId);
      message.success('Команда пользователя успешно обновлена');
    } catch {
      message.error('Ошибка при изменении команды пользователя');
    }
  };

  const handleActiveStatusToggle = async (userId, isActive) => {
    try {
      await updateUserActiveStatus(userId, isActive);
      message.success('Статус активности пользователя успешно обновлен');
    } catch {
      message.error('Ошибка при изменении статуса активности');
    }
  };

  const handleLeadBlockToggle = async (userId, isBlockedFromLeads) => {
    try {
      await updateUserLeadBlockStatus(userId, isBlockedFromLeads);
      message.success('Статус блокировки лидов успешно обновлен');
  
      // Обновляем `allUsers`
      setAllUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, isBlockedFromLeads } : user
        )
      );
  
      // Обновляем `filteredUsers` напрямую
      setFilteredUsers((prevFilteredUsers) =>
        prevFilteredUsers.map((user) =>
          user.id === userId ? { ...user, isBlockedFromLeads } : user
        )
      );
    } catch {
      message.error('Ошибка при изменении блокировки лидов');
    }
  };
  
  

  const handleLeadAssignment = async (username, teamId) => {
    try {
      if (!teamId) {
        message.error('Команда не найдена');
        return;
      }
  
      const data = await assignLeadToUser(username, teamId); // Передача teamId
      if (data) {
        message.success(`Лид назначен пользователю ${username}`);
      } else {
        message.warning('Нет доступных лидов для назначения');
      }
    } catch {
      message.error('Ошибка при присвоении лида');
    }
  };
  


  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'username',
      key: 'username',
      filterDropdown: (
        <div style={{ padding: 8 }}>
          <Input
            value={searchFilters.username}
            onChange={(e) => handleSearch(e.target.value, 'username')}
            placeholder="Поиск по имени пользователя"
            prefix={<SearchOutlined />}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
        </div>
      ),
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (role, record) => (
        <Select
          defaultValue={role}
          onChange={(newRole) => handleRoleChange(record.id, newRole)}
          style={{ width: 120 }}
        >
          <Option value="admin">Админ</Option>
          <Option value="closer">Клоузер</Option>
          <Option value="employee">Сотрудник</Option>
        </Select>
      ),
      filterDropdown: (
        <Select
          value={searchFilters.role}
          onChange={(value) => handleSearch(value, 'role')}
          style={{ width: 150 }}
          placeholder="Фильтр по роли"
        >
          <Option value="">Все</Option>
          <Option value="admin">Админ</Option>
          <Option value="closer">Клоузер</Option>
          <Option value="employee">Сотрудник</Option>
        </Select>
      ),
    },
    {
      title: 'Команда',
      dataIndex: 'teamId',
      key: 'teamId',
      render: (teamId) => teamOptions.find((team) => team.id === teamId)?.name || 'Без команды',
      filterDropdown: (
        <Select
          value={searchFilters.teamId}
          onChange={(value) => handleSearch(value, 'teamId')}
          placeholder="Выбрать команду"
          style={{ width: 150 }}
        >
          <Option value="">Все</Option>
          {teamOptions.map((team) => (
            <Option key={team.id} value={team.id.toString()}>
              {team.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Активность',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => (
        <Switch
          checked={isActive}
          onChange={(checked) => handleActiveStatusToggle(record.id, checked)}
        />
      ),
      filterDropdown: (
        <Select
          value={searchFilters.isActive}
          onChange={(value) => handleSearch(value, 'isActive')}
          style={{ width: 150 }}
          placeholder="Фильтр по активности"
        >
          <Option value="">Все</Option>
          <Option value="true">Активен</Option>
          <Option value="false">Неактивен</Option>
        </Select>
      ),
    },
    {
      title: 'Блокировка получения лидов',
      key: 'isBlockedFromLeads',
      render: (text, record) => (
        <Switch
          checked={record.isBlockedFromLeads}
          onChange={(checked) => handleLeadBlockToggle(record.id, checked)}
        />
      ),
      filterDropdown: (
        <Select
          value={searchFilters.isBlockedFromLeads}
          onChange={(value) => handleSearch(value, 'isBlockedFromLeads')}
          style={{ width: 150 }}
          placeholder="Фильтр по блокировке"
        >
          <Option value="">Все</Option>
          <Option value="true">Заблокирован</Option>
          <Option value="false">Не заблокирован</Option>
        </Select>
      ),
    },
    {
      title: 'Присвоить лид',
      key: 'assignLead',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleLeadAssignment(record.username, record.teamId)}>
          Присвоить лид
        </Button>
      ),
    },
    {
      title: 'Действия',
      key: 'actions',
      render: (text, record) => (
        <Button type="link" onClick={() => onDeleteUser(record.id)}>
          Удалить
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h2>Управление пользователями</h2>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={filteredUsers}
          columns={columns}
          rowKey="id"
          pagination={{
            current: currentPage,
            pageSize,
            total: allUsers.length,
            showSizeChanger: true,
            onChange: (page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            },
          }}
        />
      )}
    </div>
  );
};

export default UserTable;
