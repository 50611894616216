import React, { useState, useEffect } from "react";
import {
	Upload,
	Button,
	message,
	Form,
	Select,
	Spin,
	Modal,
	Typography,
	Result,
} from "antd";
import {
	UploadOutlined,
	CheckCircleOutlined,
	CloseCircleOutlined,
	FileOutlined,
} from "@ant-design/icons";
import {
	getUsers,
	getTeams,
	importLeads,
	checkTaskStatus,
} from "../../services/apiMethods";

const { Paragraph } = Typography;

const LeadImport = () => {
	const [responsibles, setResponsibles] = useState([]);
	const [teams, setTeams] = useState([]);
	const [selectedResponsible, setSelectedResponsible] = useState(null);
	const [selectedTeam, setSelectedTeam] = useState(null);
	const [selectedSource, setSelectedSource] = useState([]); // Для источников
	const [selectedCountry, setSelectedCountry] = useState([]); // Для стран
	const [selectedFile, setSelectedFile] = useState(null);
	const [taskId, setTaskId] = useState(null);
	const [status, setStatus] = useState(null);
	const [importStats, setImportStats] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isImportInProgress, setIsImportInProgress] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const users = await getUsers();
				const teams = await getTeams();
				setResponsibles(users);
				setTeams(teams);
			} catch (error) {
				message.error("Ошибка загрузки данных.");
			}
		};
		fetchData();
	}, []);

	const handleFileChange = (info) => {
		const file = info.file.originFileObj || info.file;
		if (file) {
			setSelectedFile(file);
			message.success(`Файл ${file.name} выбран.`);
		} else {
			message.error("Ошибка при выборе файла.");
		}
	};

	const removeFile = () => {
		setSelectedFile(null);
		message.info("Файл удален.");
	};

	const resetForm = () => {
		setSelectedResponsible(null);
		setSelectedTeam(null);
		setSelectedSource([]);
		setSelectedCountry([]);
		setSelectedFile(null);
		setStatus(null);
		setImportStats(null);
		setTaskId(null);
		setIsImportInProgress(false);
	};

	const showConfirmationModal = () => {
		if (!selectedFile) {
			message.error("Пожалуйста, выберите файл перед началом импорта.");
			return;
		}
		setIsModalOpen(true);
	};

	const handleConfirmImport = async () => {
		setIsModalOpen(false);
		setIsLoading(true);
		setIsImportInProgress(true);
		setImportStats(null);

		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("assignedTo", selectedResponsible || "Unassigned");
		formData.append("team", selectedTeam || null);
		formData.append("source", selectedSource.join(", ") || "Unknown");
		formData.append("country", selectedCountry.join(", ") || "Unknown");

		try {
			const response = await importLeads(formData);
			setTaskId(response.taskId);
			message.success("Импорт начат.");
			checkTaskProgress(response.taskId);
		} catch (error) {
			message.error("Ошибка импорта.");
			setIsLoading(false);
			setIsImportInProgress(false);
		}
	};

	const checkTaskProgress = async (taskId) => {
		try {
			const task = await checkTaskStatus(taskId);

			if (task.status === "in-progress") {
				setTimeout(() => checkTaskProgress(taskId), 5000);
			} else if (task.status === "completed") {
				setImportStats(task.statistics);
				setStatus("success");
				message.success("Импорт завершён.");
				setIsImportInProgress(false);
				setIsLoading(false);
			} else if (task.status === "failed") {
				setStatus("error");
				message.error("Ошибка выполнения задачи.");
				setIsImportInProgress(false);
				setIsLoading(false);
			}
		} catch (error) {
			message.error("Ошибка проверки статуса задачи.");
			setStatus("error");
			setIsImportInProgress(false);
			setIsLoading(false);
		}
	};

	const countries = ["Польша", "Норвегия", "Дания", "Узбекистан"];

	const validateSource = (value) => {
		const regex = /^[a-zA-Z0-9]+$/;
		if (value.some((item) => !regex.test(item))) {
			message.error("Источник может содержать только латинские буквы и цифры.");
			return false;
		}
		return true;
	};

	const handleSourceChange = (value) => {
		if (validateSource(value)) {
			setSelectedSource(value);
		}
	};

	return (
		<div>
			<Form layout="vertical">
				<Form.Item label="Ответственный">
					<Select
						placeholder="Выберите ответственного"
						onChange={setSelectedResponsible}
						value={selectedResponsible}
						allowClear
						showSearch // Включаем возможность поиска
						filterOption={(input, option) =>
							option?.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{responsibles.map((user) => (
							<Select.Option key={user.id} value={user.username}>
								{user.username}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Команда">
					<Select
						placeholder="Выберите команду"
						onChange={setSelectedTeam}
						value={selectedTeam}
						allowClear
						showSearch // Включаем возможность поиска
						filterOption={(input, option) =>
							option?.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{teams.map((team) => (
							<Select.Option key={team.id} value={team.name}>
								{team.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Источник">
					<Select
						mode="tags"
						placeholder="Введите источник"
						onChange={handleSourceChange}
						value={selectedSource}
						allowClear
						showSearch // Включаем возможность поиска
						filterOption={(input, option) =>
							option?.children.toLowerCase().includes(input.toLowerCase())
						}
					/>
				</Form.Item>

				<Form.Item label="Страна">
					<Select
						placeholder="Выберите страну"
						onChange={(value) =>
							setSelectedCountry(Array.isArray(value) ? value : [value])
						} // Приводим к массиву
						value={selectedCountry}
						allowClear
						showSearch
						mode="multiple" // Позволяет выбирать несколько стран
						filterOption={(input, option) =>
							option?.children.toLowerCase().includes(input.toLowerCase())
						}
					>
						{countries.map((country) => (
							<Select.Option key={country} value={country}>
								{country}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item label="Файл для импорта">
					<Upload
						beforeUpload={(file) => {
							handleFileChange({ file });
							return false;
						}}
						showUploadList={false}
					>
						<Button icon={<UploadOutlined />}>Выберите файл</Button>
					</Upload>
					{selectedFile && (
						<div style={{ marginTop: 10 }}>
							<Paragraph>
								<FileOutlined /> {selectedFile.name}
								<Button type="link" onClick={removeFile} danger>
									Удалить
								</Button>
							</Paragraph>
						</div>
					)}
				</Form.Item>

				<Button
					type="primary"
					onClick={showConfirmationModal}
					disabled={isLoading}
				>
					Начать импорт
				</Button>
			</Form>

			{isImportInProgress && (
				<div style={{ marginTop: 20 }}>
					<Spin size="large" />{" "}
					<p>Импорт выполняется, пожалуйста, подождите...</p>
				</div>
			)}

			{status && (
				<div style={{ marginTop: 20 }}>
					{status === "success" ? (
						<Result
							status="success"
							title="Импорт завершён успешно!"
							extra={[
								<Button type="primary" onClick={resetForm}>
									Сбросить форму
								</Button>,
							]}
						/>
					) : (
						<Result
							status="error"
							title="Ошибка импорта"
							extra={[
								<Button type="primary" onClick={resetForm}>
									Сбросить форму
								</Button>,
							]}
						/>
					)}
				</div>
			)}

			{importStats && (
				<div
					style={{
						marginTop: 20,
						padding: 15,
						border: "1px solid #d9d9d9",
						borderRadius: 4,
					}}
				>
					<h3>Статистика импорта:</h3>
					<Paragraph>
						<CheckCircleOutlined style={{ color: "green", marginRight: 8 }} />
						Импортировано лидов: {importStats.imported}
					</Paragraph>
					<Paragraph>
						<CloseCircleOutlined style={{ color: "orange", marginRight: 8 }} />
						Удалено дублей: {importStats.duplicates}
					</Paragraph>
					<Paragraph>
						<CloseCircleOutlined style={{ color: "red", marginRight: 8 }} />
						Пропущено строк: {importStats.skipped}
					</Paragraph>

					{importStats.duplicateLeads?.length > 0 && (
						<div>
							<h4>Список дубликатов:</h4>
							<ul>
								{importStats.duplicateLeads.map((duplicate, index) => (
									<li key={index}>
										Телефон: {duplicate.phone}, Имя: {duplicate.name}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}

			<Modal
				title="Подтвердите импорт"
				open={isModalOpen}
				onOk={handleConfirmImport}
				onCancel={() => setIsModalOpen(false)}
				okText="Да, начать"
				cancelText="Отмена"
			>
				<p>Вы уверены, что хотите начать импорт?</p>
			</Modal>
		</div>
	);
};

export default LeadImport;
