import React, { useState, useEffect } from "react";
import { Card, Select, Button, message, Form, Spin } from "antd";
import {
	getUnassignedCounts,
	reassignDatabaseToTeam,
	getTeams,
} from "../../services/apiMethods";

const { Option } = Select;

const DatabaseReassignment = () => {
	const [databases, setDatabases] = useState([]);
	const [teams, setTeams] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [form] = Form.useForm();

	useEffect(() => {
		const loadData = async () => {
			try {
				setIsLoading(true);
				const [databasesData, teamsData] = await Promise.all([
					getUnassignedCounts(), // Запрос списка баз данных
					getTeams(), // Запрос списка команд
				]);
				setDatabases(databasesData);
				setTeams(teamsData);
			} catch (error) {
				console.error("Ошибка загрузки данных:", error);
				message.error("Не удалось загрузить данные.");
			} finally {
				setIsLoading(false);
			}
		};

		loadData();
	}, []);

	const handleReassign = async (values) => {
    const { source, newTeam } = values;
  
    try {
      const response = await reassignDatabaseToTeam(source, newTeam); // newTeam будет содержать имя команды
      message.success(response.message);
      form.resetFields(); // Сброс формы после успешного выполнения
    } catch (error) {
      console.error('Ошибка при переассоциации базы:', error);
      message.error(error.response?.data?.error || 'Ошибка при переассоциации базы.');
    }
  };
  

	return (
		<Card title="Переассоциация базы с командой">
			{isLoading ? (
				<Spin tip="Загрузка данных..." /> // Показываем спиннер во время загрузки
			) : (
				<Form form={form} layout="vertical" onFinish={handleReassign}>
					<Form.Item
						name="source"
						label="Выберите базу"
						rules={[{ required: true, message: "Пожалуйста, выберите базу" }]}
					>
						<Select
							placeholder="Выберите базу"
							showSearch
							optionFilterProp="children"
							filterOption={(input, option) =>
								option?.children.toLowerCase().includes(input.toLowerCase())
							}
						>
							{databases.map((db) => (
								<Option key={`${db.source}-${db.team}`} value={db.source}>
									{`${db.source} (${db.team || "Без команды"} - Остаток: ${
										db.unassignedCount
									})`}
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
  name="newTeam"
  label="Выберите новую команду"
  rules={[{ required: true, message: "Пожалуйста, выберите команду" }]}
>
  <Select
    placeholder="Выберите команду"
    optionFilterProp="children"
    filterOption={(input, option) =>
      option?.children.toLowerCase().includes(input.toLowerCase())
    }
  >
    {teams.map((team) => (
      <Option key={team.name} value={team.name}> {/* Используем team.name вместо team.id */}
        {team.name}
      </Option>
    ))}
  </Select>
</Form.Item>
					<Button type="primary" htmlType="submit">
						Переассоциировать
					</Button>
				</Form>
			)}
		</Card>
	);
};

export default DatabaseReassignment;
