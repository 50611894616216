import React, { useState, useEffect } from "react";
import { Card, Table, Tabs, Typography, message } from "antd";
import { getUnassignedCounts } from "../../services/apiMethods";

const { Text } = Typography;
const { TabPane } = Tabs;

const TeamDatabaseCount = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadUnassignedCounts = async () => {
      try {
        setIsLoading(true);
        const unassignedCounts = await getUnassignedCounts();
        setData(unassignedCounts);
      } catch (error) {
        console.error("Ошибка загрузки данных:", error);
        message.error("Ошибка загрузки данных");
      } finally {
        setIsLoading(false);
      }
    };

    loadUnassignedCounts();
  }, []);

  const columns = [
    {
      title: "Название базы",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Команда",
      dataIndex: "team",
      key: "team",
      filters: Array.from(
        new Set(data.map((item) => item.team || "Без команды"))
      ).map((team) => ({
        text: team,
        value: team,
      })),
      onFilter: (value, record) => record.team === value,
    },
    {
      title: "Остаток лидов",
      dataIndex: "unassignedCount",
      key: "unassignedCount",
      render: (count) => <Text>{count}</Text>,
    },
  ];

  const activeBases = data.filter((item) => item.unassignedCount > 0);
  const archivedBases = data.filter((item) => item.unassignedCount === 0);

  return (
    <Card title="Информация о базах и остатке лидов">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Актуальные базы" key="1">
          <Table
            dataSource={activeBases.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="key"
          />
        </TabPane>
        <TabPane tab="Архивные базы" key="2">
          <Table
            dataSource={archivedBases.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            loading={isLoading}
            pagination={false}
            rowKey="key"
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default TeamDatabaseCount;
