import React, { useState, useEffect } from 'react';
import { List, Avatar, Card, Typography, Row, Col, Badge, Divider, Tag } from 'antd';
import moment from 'moment';

import socket from '../../socket';
import { fetchOnlineUsers, getUsers } from '../../services/apiMethods';

const { Text, Title } = Typography;

// Пример массива команд (id, name). 
// Если у вас есть эндпоинт для команд, подтяните их динамически.
const allTeams = [
  { id: 2, name: 'Орлы' },
  { id: 3, name: 'Тигры' },
  { id: 4, name: 'Центр' },
  { id: 6, name: 'Кресты' },
  { id: 7, name: 'Блокчейн' },
];

// Возвращает название команды по teamId или 'Нет команды'
const getTeamName = (teamId) => {
  const found = allTeams.find((t) => t.id === teamId);
  return found ? found.name : 'Нет команды';
};

const OnlineUsersList = ({ user }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        // Получаем «онлайн» и «всех» юзеров
        const online = await fetchOnlineUsers();
        const all = await getUsers();

        if (user.role === 'admin') {
          // Администратор видит всех
          setOnlineUsers(online);
          setAllUsers(sortByLastOnline(all));
        } else {
          // Обычный пользователь видит только teamId === user.teamId
          const filteredOnline = online.filter((u) => u.teamId === user.teamId);
          const filteredAll = all.filter((u) => u.teamId === user.teamId);

          // Если текущего пользователя нет в «онлайне», добавим его вручную
          if (!filteredOnline.some((u) => u.id === user.id)) {
            filteredOnline.push({
              ...user,
              online: true,
              sessionStart: moment().subtract(1, 'minute').toISOString(),
            });
          }

          setOnlineUsers(filteredOnline);
          setAllUsers(sortByLastOnline(filteredAll));
        }
      } catch (error) {
        console.error('Ошибка загрузки пользователей:', error);
      }
    };

    loadUsers();

    // Функция, обрабатывающая обновление «онлайн»-пользователей через сокет
    const handleUpdateOnlineUsers = async (userIds) => {
      try {
        const updatedUsers = await getUsers({ ids: userIds });

        if (user.role === 'admin') {
          setOnlineUsers(updatedUsers);
        } else {
          setOnlineUsers(updatedUsers.filter((u) => u.teamId === user.teamId));
        }
      } catch (error) {
        console.error('Ошибка обновления списка онлайн-пользователей:', error);
      }
    };

    socket.on('updateOnlineUsers', handleUpdateOnlineUsers);

    return () => {
      socket.off('updateOnlineUsers', handleUpdateOnlineUsers);
    };
  }, [user]);

  // Сортировка массива по lastOnline (более "свежие" в начале)
  const sortByLastOnline = (usersArr) => {
    return [...usersArr].sort((a, b) =>
      moment(b.lastOnline).diff(moment(a.lastOnline))
    );
  };

  // Проверяем, пользователь сейчас онлайн? 
  // (берём из стейта "onlineUsers", у которого те, кто онлайн)
  const isOnline = (u) => {
    return onlineUsers.some((ou) => ou.id === u.id);
  };

  // Форматируем, как долго человек в сети (если онлайн),
  // или когда был в последний раз (если оффлайн).
  const getActivityText = (u) => {
    if (isOnline(u)) {
      // Считаем разницу с sessionStart
      if (!u.sessionStart) return 'Сейчас онлайн (нет sessionStart)';
      const duration = moment.duration(moment().diff(moment(u.sessionStart)));
      return `В сети: ${duration.hours()}ч ${duration.minutes()}м`;
    } else {
      // Показываем, когда последний раз был онлайн (время назад)
      if (!u.lastOnline) return 'Последний визит: неизвестно';
      return `Был онлайн: ${moment(u.lastOnline).fromNow()}`;
    }
  };

  return (
    <Card bordered={false} style={{ borderRadius: 8, padding: 20 }}>
      {/* Блок "Онлайн" */}
      <Title level={4} style={{ marginBottom: 16 }}>
        {user.role === 'admin'
          ? 'Все пользователи (Онлайн)'
          : `Онлайн — «${getTeamName(user.teamId)}»`
        }
      </Title>
      <Divider />

      {/* Сетка карточек для онлайн-пользователей */}
      {onlineUsers.length === 0 ? (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <Text type="secondary">Нет пользователей онлайн</Text>
        </div>
      ) : (
        <Row gutter={[8, 8]} justify="start">
          {onlineUsers.map((u) => (
            <Col key={u.id} xs={12} sm={8} md={6} lg={4}>
              <Badge.Ribbon text="Онлайн" color="green">
                <Card
                  hoverable
                  bordered
                  style={{ borderRadius: 8, textAlign: 'center', padding: 8 }}
                >
                  <Avatar size={40} style={{ backgroundColor: '#87d068', marginBottom: 8 }}>
                    {u.username.charAt(0).toUpperCase()}
                  </Avatar>
                  <Title level={5} ellipsis={{ rows: 1 }} style={{ margin: 0, fontSize: 14 }}>
                    {u.username}
                  </Title>
                  <Tag color="blue" style={{ marginTop: 4, fontSize: 12 }}>
                    {u.role}
                  </Tag>
                  <Text type="secondary" style={{ fontSize: 12, display: 'block' }}>
                    {getTeamName(u.teamId)}
                  </Text>
                  <Text
                    type="secondary"
                    style={{ fontSize: 12, marginTop: 4, display: 'block' }}
                  >
                    {getActivityText(u)}
                  </Text>
                </Card>
              </Badge.Ribbon>
            </Col>
          ))}
        </Row>
      )}

      <Divider />

      {/* Блок "История (активность)" */}
      <Title level={4} style={{ marginBottom: 16 }}>История (активность)</Title>

      {/* Сетка карточек для всех пользователей (отфильтрованных). 
          Показываем 4 колонки, при желании можно поменять column: 5 или 6. */}
      <List
        grid={{ gutter: 16, column: 6 }}
        dataSource={allUsers}
        renderItem={(u) => (
          <List.Item>
            <Card
              size="small"
              hoverable
              bordered
              style={{
                borderRadius: 8,
                padding: 12,
                textAlign: 'center',
              }}
            >
              <Avatar
                size={40}
                style={{
                  backgroundColor: isOnline(u) ? '#87d068' : '#ccc',
                  marginBottom: 8,
                }}
              >
                {u.username.charAt(0).toUpperCase()}
              </Avatar>
              <Title
                level={5}
                ellipsis={{ rows: 1 }}
                style={{ margin: 0, fontSize: 14 }}
              >
                {u.username}
              </Title>
              <Tag color="blue" style={{ marginTop: 4, fontSize: 12 }}>
                {u.role}
              </Tag>
              <Text
                type="secondary"
                style={{ fontSize: 12, display: 'block', marginTop: 4 }}
              >
                {getTeamName(u.teamId)}
              </Text>
              <Text
                type="secondary"
                style={{ fontSize: 12, display: 'block', marginTop: 4 }}
              >
                {getActivityText(u)}
              </Text>
              <Tag
                color={isOnline(u) ? 'green' : 'gray'}
                style={{ fontSize: 12, marginTop: 8 }}
              >
                {isOnline(u) ? 'Онлайн' : 'Оффлайн'}
              </Tag>
            </Card>
          </List.Item>
        )}
      />
    </Card>
  );
};

export default OnlineUsersList;
